import revive_payload_client_eqgSEd4QCaGidlBCsuvmOiVbEsXUo2PCwS2_7ifQ5M4 from "/codebuild/output/src1648307441/src/bitbucket.org/magusa/cavu-workspaces/node_modules/.pnpm/nuxt@3.16.2_@parcel+watcher@2.5.1_@types+node@22.14.0_db0@0.3.1_eslint@8.57.1_ioredis@5_62a56d24c2f66656b20aceeceff4dbd3/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_HPkTVysO5d5t2EnXiIM3b85ArwIy_i3EudJlKSiy0IY from "/codebuild/output/src1648307441/src/bitbucket.org/magusa/cavu-workspaces/node_modules/.pnpm/nuxt@3.16.2_@parcel+watcher@2.5.1_@types+node@22.14.0_db0@0.3.1_eslint@8.57.1_ioredis@5_62a56d24c2f66656b20aceeceff4dbd3/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_ydyFaLhtPnDH1l4VJErm6aloPiDfXjW1eO1B8_cTxRY from "/codebuild/output/src1648307441/src/bitbucket.org/magusa/cavu-workspaces/node_modules/.pnpm/nuxt@3.16.2_@parcel+watcher@2.5.1_@types+node@22.14.0_db0@0.3.1_eslint@8.57.1_ioredis@5_62a56d24c2f66656b20aceeceff4dbd3/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import _0_siteConfig_wGfmQYU23YnqR0vtYUqwqFiT_DQNh9P31F2ZhBQMmCg from "/codebuild/output/src1648307441/src/bitbucket.org/magusa/cavu-workspaces/node_modules/.pnpm/nuxt-site-config@2.2.21_magicast@0.3.5_vite@6.2.5_@types+node@22.14.0_jiti@2.4.2_terser_3be588544346f187f589bea899759df9/node_modules/nuxt-site-config/dist/runtime/nuxt/plugins/0.siteConfig.js";
import payload_client_kk1WRnmlGPtd4ebfquwgcEtQ3JfsEqVkK36dUAbWZrI from "/codebuild/output/src1648307441/src/bitbucket.org/magusa/cavu-workspaces/node_modules/.pnpm/nuxt@3.16.2_@parcel+watcher@2.5.1_@types+node@22.14.0_db0@0.3.1_eslint@8.57.1_ioredis@5_62a56d24c2f66656b20aceeceff4dbd3/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_D2cxjTDUPxKS4VVzFpROwajPfKcfsarh5G8Jm5Cm8h8 from "/codebuild/output/src1648307441/src/bitbucket.org/magusa/cavu-workspaces/node_modules/.pnpm/nuxt@3.16.2_@parcel+watcher@2.5.1_@types+node@22.14.0_db0@0.3.1_eslint@8.57.1_ioredis@5_62a56d24c2f66656b20aceeceff4dbd3/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_tutOOPnsS3gJAbWNhMVcHCWVM45_geTOVjlRHLSBrck from "/codebuild/output/src1648307441/src/bitbucket.org/magusa/cavu-workspaces/node_modules/.pnpm/nuxt@3.16.2_@parcel+watcher@2.5.1_@types+node@22.14.0_db0@0.3.1_eslint@8.57.1_ioredis@5_62a56d24c2f66656b20aceeceff4dbd3/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_7vCIXK0_yiEX8YkAsvpZsgg2QJUcVBxhL3VnoBQaJdc from "/codebuild/output/src1648307441/src/bitbucket.org/magusa/cavu-workspaces/node_modules/.pnpm/nuxt@3.16.2_@parcel+watcher@2.5.1_@types+node@22.14.0_db0@0.3.1_eslint@8.57.1_ioredis@5_62a56d24c2f66656b20aceeceff4dbd3/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_9coHllPcYKgTa7dtvOZJUCG7lVt4YF1EJtIohUHi240 from "/codebuild/output/src1648307441/src/bitbucket.org/magusa/cavu-workspaces/node_modules/.pnpm/@pinia+nuxt@0.5.5_magicast@0.3.5_typescript@5.8.3_vue@3.5.13_typescript@5.8.3_/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import switch_locale_path_ssr_6I68_sY5MijzrRr63e6kc1rlDwK83S5GOyWwUR1eOxA from "/codebuild/output/src1648307441/src/bitbucket.org/magusa/cavu-workspaces/node_modules/.pnpm/@nuxtjs+i18n@9.5.2_@vue+compiler-dom@3.5.13_eslint@8.57.1_magicast@0.3.5_rollup@4.39.0_vue@3.5.13_typescript@5.8.3_/node_modules/@nuxtjs/i18n/dist/runtime/plugins/switch-locale-path-ssr.js";
import route_locale_detect__lOcPD54_ttLKw2KtAMxQJtjZEaA94YoLFtzUtn_K0Y from "/codebuild/output/src1648307441/src/bitbucket.org/magusa/cavu-workspaces/node_modules/.pnpm/@nuxtjs+i18n@9.5.2_@vue+compiler-dom@3.5.13_eslint@8.57.1_magicast@0.3.5_rollup@4.39.0_vue@3.5.13_typescript@5.8.3_/node_modules/@nuxtjs/i18n/dist/runtime/plugins/route-locale-detect.js";
import i18n_8aHXXQEDPow3CMVSNgw1LScKbgGdKQV2LpfZ__eMZug from "/codebuild/output/src1648307441/src/bitbucket.org/magusa/cavu-workspaces/node_modules/.pnpm/@nuxtjs+i18n@9.5.2_@vue+compiler-dom@3.5.13_eslint@8.57.1_magicast@0.3.5_rollup@4.39.0_vue@3.5.13_typescript@5.8.3_/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.js";
import components_plugin_z4hgvsiddfKkfXTP6M8M4zG5Cb7sGnDhcryKVM45Di4 from "/codebuild/output/src1648307441/src/bitbucket.org/magusa/cavu-workspaces/apps/sps/.nuxt/components.plugin.mjs";
import prefetch_client_t_YoLipXWo05AVAp8__on620uifTisREfXqTKyYwC0U from "/codebuild/output/src1648307441/src/bitbucket.org/magusa/cavu-workspaces/node_modules/.pnpm/nuxt@3.16.2_@parcel+watcher@2.5.1_@types+node@22.14.0_db0@0.3.1_eslint@8.57.1_ioredis@5_62a56d24c2f66656b20aceeceff4dbd3/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_iWn8_GdXitKtqrehWImnVMjDFJxFmauHmo7ehWl7JRo from "/codebuild/output/src1648307441/src/bitbucket.org/magusa/cavu-workspaces/node_modules/.pnpm/@nuxtjs+device@3.2.4/node_modules/@nuxtjs/device/dist/runtime/plugin.js";
import plugin_LsqUbFusQTCMbn48_Km66UJJoYjfClsMJCmdw5Ek38o from "/codebuild/output/src1648307441/src/bitbucket.org/magusa/cavu-workspaces/node_modules/.pnpm/@nuxt+icon@1.11.0_magicast@0.3.5_vite@6.2.5_@types+node@22.14.0_jiti@2.4.2_terser@5.39._f4730a09f575cf583089161e1358315f/node_modules/@nuxt/icon/dist/runtime/plugin.js";
import plugin_A8HpyU0Q2saz5x0SzVME2_txWBGkm05nwU2FofwKk0I from "/codebuild/output/src1648307441/src/bitbucket.org/magusa/cavu-workspaces/node_modules/.pnpm/@storyblok+nuxt@6.2.4_vue@3.5.13_typescript@5.8.3_/node_modules/@storyblok/nuxt/dist/runtime/plugin.js";
import plugin_6WLJXt0fbrq64xZk94Or5NkF_0KXo4GtkKJLi28DinI from "/codebuild/output/src1648307441/src/bitbucket.org/magusa/cavu-workspaces/node_modules/.pnpm/pinia-plugin-persistedstate@4.2.0_@pinia+nuxt@0.5.5_magicast@0.3.5_typescript@5.8.3_vue_7535a39b0647029226d9eaca4ca06629/node_modules/pinia-plugin-persistedstate/dist/nuxt/runtime/plugin.js";
import base64_XVE2eeaoLb90zCtWHcXzwCVA_MZfMsafoJruckKetkE from "/codebuild/output/src1648307441/src/bitbucket.org/magusa/cavu-workspaces/apps/_base/plugins/base64.ts";
import ssg_detect_ll5u6XkVNjfONfO7JGXpDa3NthyoaeXPGSLIGOITCvE from "/codebuild/output/src1648307441/src/bitbucket.org/magusa/cavu-workspaces/node_modules/.pnpm/@nuxtjs+i18n@9.5.2_@vue+compiler-dom@3.5.13_eslint@8.57.1_magicast@0.3.5_rollup@4.39.0_vue@3.5.13_typescript@5.8.3_/node_modules/@nuxtjs/i18n/dist/runtime/plugins/ssg-detect.js";
export default [
  revive_payload_client_eqgSEd4QCaGidlBCsuvmOiVbEsXUo2PCwS2_7ifQ5M4,
  unhead_HPkTVysO5d5t2EnXiIM3b85ArwIy_i3EudJlKSiy0IY,
  router_ydyFaLhtPnDH1l4VJErm6aloPiDfXjW1eO1B8_cTxRY,
  _0_siteConfig_wGfmQYU23YnqR0vtYUqwqFiT_DQNh9P31F2ZhBQMmCg,
  payload_client_kk1WRnmlGPtd4ebfquwgcEtQ3JfsEqVkK36dUAbWZrI,
  navigation_repaint_client_D2cxjTDUPxKS4VVzFpROwajPfKcfsarh5G8Jm5Cm8h8,
  check_outdated_build_client_tutOOPnsS3gJAbWNhMVcHCWVM45_geTOVjlRHLSBrck,
  chunk_reload_client_7vCIXK0_yiEX8YkAsvpZsgg2QJUcVBxhL3VnoBQaJdc,
  plugin_vue3_9coHllPcYKgTa7dtvOZJUCG7lVt4YF1EJtIohUHi240,
  switch_locale_path_ssr_6I68_sY5MijzrRr63e6kc1rlDwK83S5GOyWwUR1eOxA,
  route_locale_detect__lOcPD54_ttLKw2KtAMxQJtjZEaA94YoLFtzUtn_K0Y,
  i18n_8aHXXQEDPow3CMVSNgw1LScKbgGdKQV2LpfZ__eMZug,
  components_plugin_z4hgvsiddfKkfXTP6M8M4zG5Cb7sGnDhcryKVM45Di4,
  prefetch_client_t_YoLipXWo05AVAp8__on620uifTisREfXqTKyYwC0U,
  plugin_iWn8_GdXitKtqrehWImnVMjDFJxFmauHmo7ehWl7JRo,
  plugin_LsqUbFusQTCMbn48_Km66UJJoYjfClsMJCmdw5Ek38o,
  plugin_A8HpyU0Q2saz5x0SzVME2_txWBGkm05nwU2FofwKk0I,
  plugin_6WLJXt0fbrq64xZk94Or5NkF_0KXo4GtkKJLi28DinI,
  base64_XVE2eeaoLb90zCtWHcXzwCVA_MZfMsafoJruckKetkE,
  ssg_detect_ll5u6XkVNjfONfO7JGXpDa3NthyoaeXPGSLIGOITCvE
]